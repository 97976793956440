<template>
  <div class="wrapper-content">
    <div class="details">
      <div class="title">{{ data.journalisTitle }}</div>
      <div class="date">
        信息来源：{{ data.message }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        发布日期：{{ formatDateTime(data.createTime) }}
      </div>
      <div class="ql-editor" v-html="data.journalisContent"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.data = this.$route.query.item;
    console.log(this.data);
  },
};
</script>
<style lang="scss" scoped>
.wrapper-content {
  min-height: 100%;
  .details {
    width: 100%;
    padding: 30px 0;
    .title {
      text-align: center;
      font-size: 22px;
      color: #1e50ae;
      font-weight: bold;
    }
    .date {
      text-align: center;
      font-size: 14px;
      color: #666666;
      margin: 30px 0;
    }
    .ql-editor {
      line-height: 34px;
      font-size: 16px;
    }
  }
}
</style>
